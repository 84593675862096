import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`25:00 AMRAP:`}</p>
    <p>{`200M Sandbag Run (40`}{`#`}{`)`}</p>
    <p>{`5 Bar Muscle Ups`}</p>
    <p>{`10 Single Arm DB Clean & Jerk (50/35`}{`#`}{`, 5/arm, then alternate arms)`}</p>
    <p>{`15 Ring Rows`}</p>
    <p>{`20 Back Extensions`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday is our free class at 9:00 & 10:00am so bring a
friend!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      